<template>
  <div>
    <app-checkbox v-model="enabled" @input="saveChange($event)">
      {{ alert }}
    </app-checkbox>
  </div>
</template>
<script>
import { StoreService } from "../../store-module/service";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    alert: { default: "" },
    client_id: { default: 0 }
  },

  data: () => ({
    enabled: false
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    StoreService.getClientAlerts({
      alert: this.alert,
      client_id: this.client_id
    }).then(res => {
      if (res.length > 0) {
        this.enabled = 1;
      }
    });
  },

  methods: {
    saveChange(to) {
      if (to) {
        StoreService.saveClientAlert({
          alert: this.alert,
          client_id: this.client_id
        });
      } else {
        StoreService.saveClientAlert({
          alert: this.alert,
          client_id: this.client_id,
          to_disable: 1
        });
      }
    }
  }
};
</script>

<style></style>
