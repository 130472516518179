<template>
  <div>
    <ClientAlertCheckbox
      alert="Alergico a la Penicilina"
      :client_id="client_id"
    ></ClientAlertCheckbox>
    <ClientAlertCheckbox
      alert="Problemas cardiacos"
      :client_id="client_id"
    ></ClientAlertCheckbox>
  </div>
</template>
<script>
import ClientAlertCheckbox from "../../general-module/alerts/ClientAlertCheckbox";

export default {
  components: {
    ClientAlertCheckbox
  },

  // directives
  // filters

  props: {
    client_id: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
